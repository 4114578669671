<template>
  <div class="setting-item s-form-data">
    <div class="s-form-data__title">
      {{ setting.name }}
    </div>
    <template v-if="!isEdit">
      <p v-if="fieldIdValue">
        Техническое имя: {{ fieldIdValue }}
      </p>
      <div class="s-form-data__actions mt-10">
        <esmp-button
          size="small"
          view="outline"
          @click="isEdit = true"
        >
          Изменить
        </esmp-button>
        <esmp-button
          class="ml-10"
          size="small"
          :disabled="!fieldIdValue"
          @click="clearValue"
        >
          Очистить
        </esmp-button>
      </div>
    </template>
    <template v-else>
      <esmp-select
        class="s-form-data__select"
        placeholder="Поиск услуги"
        filterable
        :loading="searchLoading"
        :remote-method="debouncedSearch"
        v-model="serviceId"
      >
        <esmp-select-option
          v-for="item in serviceList"
          :key="item.id"
          :value="item.id"
        >
          {{ item.name }}
        </esmp-select-option>
      </esmp-select>
      <esmp-select
        class="s-form-data__select mt-10"
        placeholder="Выбор формы"
        filterable
        :disabled="!serviceId"
        v-model="formId"
      >
        <esmp-select-option
          v-for="item in formList"
          :key="item.id"
          :value="item.id"
        >
          {{ item.name }}
        </esmp-select-option>
      </esmp-select>
      <esmp-select
        class="s-form-data__select mt-10"
        placeholder="Выбор поля"
        filterable
        :disabled="!formId"
        v-model="fieldId"
      >
        <esmp-select-option
          v-for="item in fieldList"
          :key="item.id"
          :value="item.id"
        >
          {{ getName(item.settings) }} ({{ item.id }})
        </esmp-select-option>
      </esmp-select>

      <div class="s-form-data__actions mt-10">
        <esmp-button
          size="small"
          view="outline"
          @click="isEdit = false"
        >
          Отменить
        </esmp-button>
        <esmp-button
          class="ml-10"
          size="small"
          :disabled="!fieldId"
          @click="save"
        >
          Подтвердить
        </esmp-button>
      </div>
    </template>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { SEARCH_DELAY } from '@/constants/search';

const FORM_FIELD = 'dataFromTicketFormFieldSource';

export default {
  name: 'SFormData',
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEdit: false,
      searchLoading: false,
      serviceList: [],
      serviceId: null,
      formList: [],
      formId: null,
      fieldList: [],
      fieldId: null,
    };
  },
  computed: {
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    fieldIdValue() {
      return this.setting.childs.find((c) => c.techName === FORM_FIELD)?.value || null;
    },
  },
  methods: {
    search(query) {
      this.searchLoading = true;
      if (query && query.length > 2) {
        this.$API.services.simpleSearch(query).then(({ data }) => {
          this.serviceList = data;
        }).finally(() => {
          this.searchLoading = false;
        });
      } else {
        this.serviceList = [];
        this.searchLoading = false;
      }
    },
    getName(settings) {
      return settings.find((s) => s.techName === 'simpleTextValue' || s.techName === 'name')?.value || 'Нет имени';
    },
    save() {
      this.setValue(true, this.fieldId);
      this.isEdit = false;
    },
    clearValue() {
      this.serviceList = [];
      this.serviceId = null;
      this.formList = [];
      this.formId = null;
      this.fieldList = [];
      this.fieldId = null;

      this.setValue(false, null);
    },
    setValue(hasValue, value) {
      const formField = this.localSetting.childs.find((c) => c.techName === FORM_FIELD);

      if (formField) {
        this.localSetting.value = hasValue;
        formField.value = value;
      }
    },
  },
  watch: {
    serviceId(value) {
      if (value) {
        this.$API.forms.getForms(this.serviceId).then(({ data }) => {
          this.formList = data;
        });
      } else {
        this.formList = [];
        this.formId = null;
        this.fieldList = [];
        this.fieldId = null;
      }
    },
    formId(value) {
      if (value) {
        this.$API.forms.getForm(this.serviceId, this.formId).then(({ data }) => {
          this.fieldList = data.formItems;
        });
      } else {
        this.fieldList = [];
        this.fieldId = null;
      }
    },
  },
  created() {
    this.debouncedSearch = debounce(this.search, SEARCH_DELAY);
  },
};
</script>

<style lang="scss" scoped>
.s-form-data {
  &__title {
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
